document,
  addEventListener('turbolinks:load', function () {
    // 配信ページ追加ボタンがクリックされたとき
    document
      .querySelectorAll('.web_push_add_url_button')
      .forEach(function (element) {
        element.addEventListener('click', click_web_push_url);
      });

    function click_web_push_url(e) {
      const webPushUrlsContainer = e.target.closest(
        '.web_push_urls_container'
      );

      const webPushUrlFields = webPushUrlsContainer.querySelectorAll(
        '.url-field'
      );

      let webPushUrlField;

      if (webPushUrlFields.length == 0) {
        webPushUrlField = document.createElement('div');

        webPushUrlField.classList.add('url-field');

        webPushUrlField.innerHTML = `
          <div class="url-field-contents">
            <div class="input-text">
              <input
                placeholder="https://"
                name="web_push[${webPushUrlsContainer.id}][0][name]"
                value=""
                class="field_name"
                type="text"
                id="web_push_${webPushUrlsContainer.id}_name"
              >
            </div>
            <input
              name="web_push[${webPushUrlsContainer.id}][0][m_node_type_id]"
              value="" class="web_push_field_m_node_type_id"
              type="hidden"
              id="web_push_${webPushUrlsContainer.id}_m_node_type_id"
            >
            <input name="web_push[${webPushUrlsContainer.id}][0]"
              value=""
              class="field_web_push_node_id"
              type="hidden"
              id="web_push_${webPushUrlsContainer.id}_web_push_node_id"
            >
            <div class="field-remove">
              <button type="button" class="remove-field btn-remove">
                <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                </svg>
                削除
              </button>
            </div>
      `;
      } else {
        webPushUrlField = webPushUrlFields[0].cloneNode(true);
        webPushUrlField.querySelector('.field_name').value =
          '';
        webPushUrlField.querySelector(
          '.web_push_field_m_node_type_id'
        ).value = '';
        webPushUrlField.querySelector(
          '.field_web_push_node_id'
        ).value = '';
        webPushUrlField.closest('.url-field').style.display =
          'block';
      }

      webPushUrlField
        .querySelector('.remove-field')
        .addEventListener('click', ClickRemove);
      webPushUrlsContainer.appendChild(webPushUrlField);
      reset_m_node_id();
    }

    document
      .querySelectorAll('.remove-field')
      .forEach((element) => {
        element.addEventListener('click', ClickRemove);
      });

    // 削除ボタンが押されたフォームを非表示にして、URLのバリューを空にする
    function ClickRemove(element) {
      element.target.closest('.url-field').style.display = 'none';
      element.target
        .closest('.url-field')
        .querySelector('.field_name').value = '';
      reset_m_node_id();
    }

    // URL欄の数に変更があった時にindexを振り直してname属性を更新する
    function reset_m_node_id() {
      const webPushUrlsContainers = document.querySelectorAll(
        '.web_push_urls_container'
      );

      webPushUrlsContainers.forEach((element, index) => {
        const webPushUrlsContainer = element;
        const webPushUrlFields =
          webPushUrlsContainer.querySelectorAll(
            '.url-field'
          );

        webPushUrlFields.forEach((element, index) => {
          element
            .querySelector('.field_name')
            .setAttribute(
              'name',
              `web_push[${webPushUrlsContainer.id}][${index}][name]`
            );
          element
            .querySelector('.web_push_field_m_node_type_id')
            .setAttribute(
              'name',
              `web_push[${webPushUrlsContainer.id}][${index}][m_node_type_id]`
            );
          element
            .querySelector('.field_web_push_node_id')
            .setAttribute(
              'name',
              `web_push[${webPushUrlsContainer.id}][${index}][web_push_node_id]`
            );
        });
      });
    }
    reset_m_node_id();
  });
