import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

// メディアアセットでjqueryを使う記述があるため
import $ from 'jquery';
import './content_url_fields';
import './content_url_fields2';
import './content_radio_fields.js';
import './content_image_fields';
import './content_button_fields';
import './content_preview';
import './mail_magazine_url_fields';
import './mail_magazine_file_fields';
import './mail_magazine_send_fields';
import './drag_and_drop';
import './who_service_match_user';
import './content_select_template';
import './web_push_url_fields';
import './web_push_send_fields';

import './tinymce.js';

Rails.start();
Turbolinks.start();
