document,
  addEventListener('turbolinks:load', function () {
    // ラジオボタンにイベントリスナーを追加
    document
    .querySelectorAll('#designe_radio_send_method input')
    .forEach((element) => {
      element.addEventListener('click', click_screen_send_method_radio);
    });

  // ページロード時のラジオボタンの状態をチェックし、適切なエリアを表示
  document
    .querySelectorAll('#designe_radio_send_method input')
    .forEach((element) => {
      if (element.checked) {
        click_screen_send_method_radio({ target: element });
      }
    });

  // ---------------------------------------------------------------
  // BASIC 送信日時指定/送信タイミング指定のラジオボタンをクリックしたときの画面の切り替え
  // 送信日時指定：送信日時フォーム
  // 送信タイミング指定：予約からの日数フォーム、送信時間フォーム
  // ---------------------------------------------------------------
  function click_screen_send_method_radio(e) {
    if (e.target.dataset.type == 'is_send_method_date') {
      // エリアを表示・非表示にする
      document.getElementById('area_send_method_date').style.display = '';
      document.getElementById('area_send_method_timing').style.display = 'none';
      // 必須属性を設定・解除する
      document.querySelector('[name="web_push[send_date]"]').setAttribute('required', 'true');
      document.querySelector('[name="web_push[days_after_booking]"]').removeAttribute('required');
      document.querySelector('[name="web_push[send_time]"]').removeAttribute('required');
      // 必須表示を解除する
      document.querySelector('.yoyaku-required').innerHTML = '';
    } else {
      // エリアを表示・非表示にする
      document.getElementById('area_send_method_date').style.display = 'none';
      document.getElementById('area_send_method_timing').style.display = '';
      // 必須属性を設定・解除する
      document.querySelector('[name="web_push[send_date]"]').removeAttribute('required');
      document.querySelector('[name="web_push[days_after_booking]"]').setAttribute('required', 'true');
      document.querySelector('[name="web_push[send_time]"]').setAttribute('required', 'true');
      // 必須表示を追加する
      document.querySelector('.yoyaku-required').innerHTML = '(※必須)';
      document.querySelector('.yoyaku-required').style.color = 'red';
    }
    // 送信ボタンの有効/無効を切り替える
    toggleSubmitButton();
  }

  // ---------------------------------------------------------------
  // フォーム送信時のチェック処理
  // ---------------------------------------------------------------
  document.querySelectorAll('#web_push-form').forEach((form) => {
    // 予約項目のチェックボックスにイベントリスナーを追加
    document.querySelectorAll('input[name="web_push[m_booking_item_ids][]"]').forEach((checkbox) => {
      checkbox.addEventListener('change', toggleSubmitButton);
    });

    // 初期状態で送信ボタンを無効化するかどうかをチェック
    toggleSubmitButton();
  });

  // ---------------------------------------------------------------
  // 送信ボタンの有効/無効を切り替える関数
  // ---------------------------------------------------------------
  function toggleSubmitButton() {
    const form = document.querySelector('#web-push-form');
    const sendMethodTiming = document.querySelector('input[name="web_push[send_method]"]:checked').dataset.type === 'is_send_method_timing';
    const checkedItems = document.querySelectorAll('input[name="web_push[m_booking_item_ids][]"]:checked');
    const messageElement = document.querySelector('.toggle-message');
    if (sendMethodTiming) {
      if (checkedItems.length > 0) {
        form.querySelector('[type="submit"]').disabled = false;
        if (messageElement) messageElement.innerText = ''; // メッセージをクリア
      } else {
        form.querySelector('[type="submit"]').disabled = true;
        if (messageElement) {
          messageElement.innerText = '予約項目を最低1つ選択してください';
          messageElement.style.color = 'red'; // メッセージの色を赤に設定
        }
      }
    } else {
      form.querySelector('[type="submit"]').disabled = false;
      if (messageElement) messageElement.innerText = ''; // メッセージをクリア
    }
  }
});
